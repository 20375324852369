/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { useState, useContext } from 'react';
import PropTypes from "prop-types";
import { useStaticQuery, graphql } from "gatsby";
import { useIdleTimer } from 'react-idle-timer';

// import "../../src/styles/animations.css";

// import * as FontStyles from "./src/fonts/fonts.css"

const Layout = ({ children, className, homePath, styles, timeoutOverride }) => {
  const timeoutSeconds = (timeoutOverride) => {
    var myTimeout = parseInt(timeoutOverride);
    if (isNaN(myTimeout)) {
      myTimeout = 180; // default
    }
    // console.log("setting timeout " + myTimeout)
    return myTimeout;
  }

  const handleOnIdle = event => {
    if (homePath) {
      window.location.pathname = homePath;
    } else {
      window.location.reload();
    }
  }

  const handleOnActive = event => {
    console.log('user is active', event)
    console.log('time remaining', getRemainingTime())
  }

  const handleOnAction = (e) => {
    console.log('user did something', e)
  }

  const { getRemainingTime } = useIdleTimer({
    timeout: 1000 * timeoutSeconds(timeoutOverride),
    onIdle: handleOnIdle,
    onActive: handleOnActive,
    onAction: handleOnAction,
    debounce: 500
  })

  return (
    <>
      <style>
        {styles}
      </style>
      <main className={className}>
        {children}
      </main>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
}

export default Layout
